export const convertHexToRGBA = (hexCode, opacity = 1) => {
  if (hexCode) {
    let hex = hexCode.replace("#", "")

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
    }

    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100
    }

    return `rgba(${r},${g},${b},${opacity})`
  }
}

export const contrastColour = backgroundColour => {
  //backgroundColour.designSystemColor.color.hex ===
  let textColour = ""
  let svg = {}
  let divider = {}
  let buttonColour = {}
  let spiro = {}
  let tonalLight = {}
  let pie = {}
  let line = {}
  let contrastText = {}

  const checkedColour = backgroundColour?.label

  switch (checkedColour?.toLowerCase()) {
    //Navy
    case "navy":
      textColour = "#ffffff"
      contrastText = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      tonalLight = {
        mui: "var(--secondary-mid)",
        hex: "var(--secondary-mid)",
      }
      svg = {
        default: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      line = {
        mui: "var(--secondary-main)",
        hex: "var(--secondary-main)",
      }
      pie = {
        default: {
          mui: "var(--secondary-mid)",
          hex: "var(--secondary-mid)",
        },
        active: {
          mui: "var(--secondary-main)",
          hex: "var(--secondary-main)",
        },
      }
      spiro = {
        fill: "var(--white-main)",
        opacity: 0.4,
      }
      divider = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break
    // Navy Uncoated
    case "navy uncoated":
      textColour = "#ffffff"
      contrastText = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      tonalLight = {
        mui: "var(--secondary-light)",
        hex: "var(--secondary-light)",
      }
      pie = {
        default: {
          mui: "var(--secondary-mid)",
          hex: "var(--secondary-mid)",
        },
        active: {
          mui: "var(--secondary-light)",
          hex: "var(--secondary-light)",
        },
      }
      line = {
        mui: "var(--secondary-mid)",
        hex: "var(--secondary-mid)",
      }
      svg = {
        default: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      spiro = {
        fill: "var(--white-main)",
        opacity: 0.4,
      }
      divider = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break
    // Mid Navy
    case "mid navy":
      textColour = "#ffffff"
      contrastText = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      tonalLight = {
        mui: "var(--secondary-mid)",
        hex: "var(--secondary-mid)",
      }
      svg = {
        default: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      line = {
        mui: "var(--secondary-mid)",
        hex: "var(--secondary-mid)",
      }
      pie = {
        default: {
          mui: "var(--secondary-mid)",
          hex: "var(--secondary-mid)",
        },
        active: {
          mui: "var(--secondary-main)",
          hex: "var(--secondary-main)",
        },
      }
      spiro = {
        fill: "var(--white-main)",
        opacity: 0.4,
      }
      divider = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break
    // Coral
    case "coral":
      textColour = "#ffffff"
      contrastText = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      tonalLight = {
        mui: "var(--primary-light)",
        hex: "var(--primary-light)",
      }
      svg = {
        default: {
          mui: "var(--primary-lightest)",
          hex: "var(--primary-lightest)",
        },
        active: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
      }
      line = {
        mui: "var(--primary-main)",
        hex: "var(--primary-main)",
      }
      pie = {
        default: {
          mui: "var(--secondary-mid)",
          hex: "var(--secondary-mid)",
        },
        active: {
          mui: "var(--secondary-main)",
          hex: "var(--secondary-main)",
        },
      }
      spiro = {
        fill: "var(--primary-lightest)",
        opacity: 1,
      }
      divider = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break
    // Coral Light
    case "coral light":
      textColour = "#ffffff"
      contrastText = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      tonalLight = {
        mui: "var(--primary-main)",
        hex: "var(--primary-main)",
      }
      svg = {
        default: {
          mui: "var(--secondary-main)",
          hex: "var(--secondary-main)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      line = {
        mui: "var(--primary-main)",
        hex: "var(--primary-main)",
      }
      pie = {
        default: {
          mui: "var(--primary-light)",
          hex: "var(--primary-light)",
        },
        active: {
          mui: "var(--primary-lightest)",
          hex: "var(--primary-lightest)",
        },
      }
      spiro = {
        fill: "var(--white-main)",
        opacity: 1,
      }
      divider = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break
    // Coral Lighter
    case "coral lighter":
      textColour = "var(--text-main)"
      contrastText = {
        mui: "var(--text-main)",
        hex: "var(--text-main)",
      }
      tonalLight = {
        mui: "var(--primary-lightest)",
        hex: "var(--primary-lightest)",
      }
      svg = {
        default: {
          mui: "var(--secondary-main)",
          hex: "var(--secondary-main)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      line = {
        mui: "var(--primary-main)",
        hex: "var(--primary-main)",
      }
      pie = {
        default: {
          mui: "var(--primary-lighter)",
          hex: "var(--primary-lighter)",
        },
        active: {
          mui: "var(--primary-main)",
          hex: "var(--primary-lightest)",
        },
      }
      spiro = {
        fill: "var(--text-mid)",
        opacity: 1,
      }
      divider = {
        mui: "var(--text-mid)",
        hex: "var(--text-mid)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break
    // Coral Lightest
    case "coral lightest":
      textColour = "var(--text-main)"
      contrastText = {
        mui: "var(--text-main)",
        hex: "var(--text-main)",
      }
      tonalLight = {
        mui: "var(--primary-lighter)",
        hex: "var(--primary-lighter)",
      }
      svg = {
        default: {
          mui: "var(--secondary-main)",
          hex: "var(--secondary-main)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      line = {
        mui: "var(--primary-main)",
        hex: "var(--primary-main)",
      }
      pie = {
        default: {
          mui: "var(--primary-lightest)",
          hex: "var(--primary-lightest)",
        },
        active: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
      }
      spiro = {
        fill: "var(--text-mid)",
        opacity: 1,
      }
      divider = {
        mui: "var(--text-mid)",
        hex: "var(--text-mid)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break
    // Light Grey
    case "light grey":
      textColour = "var(--secondary-main)"
      contrastText = {
        mui: "var(--secondary-main)",
        hex: "var(--secondary-main)",
      }
      tonalLight = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      svg = {
        default: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      line = {
        mui: "var(--text-mid)",
        hex: "var(--text-mid)",
      }
      pie = {
        default: {
          mui: "#ffffff",
          hex: "var(--white-main)",
        },
        active: {
          mui: "var(--grey-main)",
          hex: "var(--grey-main)",
        },
      }
      spiro = {
        fill: "var(--secondary-main)",
        opacity: 0.4,
      }
      divider = {
        mui: "var(--primary-main)",
        hex: "var(--primary-main)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break
    // Text Grey
    case "text grey":
      textColour = "#ffffff"
      contrastText = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      tonalLight = {
        mui: "var(--text-mid)",
        hex: "var(--text-mid)",
      }
      svg = {
        default: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      line = {
        mui: "var(--text-main)",
        hex: "var(--text-main)",
      }
      pie = {
        default: {
          mui: "var(--text-light)",
          hex: "var(--text-light)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      spiro = {
        fill: "var(--white-main)",
        opacity: 0.4,
      }
      divider = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break
    // Mid Grey
    case "mid grey":
      textColour = "#ffffff"
      contrastText = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      tonalLight = {
        mui: "var(--text-primary)",
        hex: "var(--text-primary)",
      }
      svg = {
        default: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      line = {
        mui: "var(--text-main)",
        hex: "var(--text-main)",
      }
      pie = {
        default: {
          mui: "var(--text-light)",
          hex: "var(--text-light)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      spiro = {
        fill: "var(--white-main)",
        opacity: 0.4,
      }
      divider = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break
    // White
    case "white":
      textColour = "var(--secondary-main)"
      contrastText = {
        mui: "var(--secondary-main)",
        hex: "var(--secondary-main)",
      }
      tonalLight = {
        mui: "var(--primary-lightest)",
        hex: "var(--primary-lightest)",
      }
      svg = {
        default: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
        active: {
          mui: "#ffffff",
          hex: "var(--white-main)",
        },
      }
      line = {
        mui: "var(--primary-main)",
        hex: "var(--primary-main)",
      }
      pie = {
        default: {
          mui: "var(--primary-lightest)",
          hex: "var(--primary-lightest)",
        },
        active: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
      }
      spiro = {
        fill: "var(--secondary-main)",
        opacity: 0.4,
      }
      divider = {
        mui: "var(--secondary-main)",
        hex: "var(--secondary-main)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break

    // Text Grey
    // Mid Grey
    case "Mid grey":
      textColour = "#ffffff"
      contrastText = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      tonalLight = {
        mui: "var(--text-primary)",
        hex: "var(--text-primary)",
      }
      svg = {
        default: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      line = {
        mui: "var(--text-main)",
        hex: "var(--text-main)",
      }
      pie = {
        default: {
          mui: "var(--text-light)",
          hex: "var(--text-light)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      spiro = {
        fill: "var(--white-main)",
        opacity: 0.4,
      }
      divider = {
        mui: "#ffffff",
        hex: "var(--white-main)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
      break

    default:
      textColour = "var(--text-main)"
      contrastText = {
        mui: "var(--text-main)",
        hex: "var(--text-main)",
      }
      tonalLight = {
        mui: "var(--primary-lighter)",
        hex: "var(--primary-lighter)",
      }
      svg = {
        default: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
        active: {
          mui: "var(--text-mid)",
          hex: "var(--text-mid)",
        },
      }
      line = {
        mui: "var(--primary-main)",
        hex: "var(--primary-main)",
      }
      pie = {
        default: {
          mui: "var(--primary-lightest)",
          hex: "var(--primary-lightest)",
        },
        active: {
          mui: "var(--primary-main)",
          hex: "var(--primary-main)",
        },
      }
      spiro = {
        fill: "var(--primary-main)",
        opacity: 1,
      }
      divider = {
        mui: "#ffffff",
        hex: "var(--text-main)",
      }
      buttonColour = {
        contained: "var(--primary-main)",
        outlined: "#ffffff",
      }
  }
  return {
    svg,
    spiro,
    divider,
    textColour,
    buttonColour,
    tonalLight,
    pie,
    line,
    contrastText,
  }
}
