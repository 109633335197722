import React from "react"
import { Button as GatsbyButton } from "gatsby-theme-material-ui"
import { Button, Box } from "@mui/material"
import { contrastColour } from "../utils/contrastColour"

export const ButtonFormat = props => {
  const { node, sx, endIcon, variant } = props

  let definedInternal = node?.link?.internal?.slug?.current
  const definedExternal = node?.link?.external

  if (node?.link?.internal?._type === "post") {
    definedInternal = `blog/${node?.link?.internal?.category?.slug?.current}/${definedInternal}`
  }
  if (node?.link?.internal?._type === "caseStudy") {
    definedInternal = `/case-studies/${definedInternal}`
  }
  if (node?.link?.internal?._type === "teamMembers") {
    definedInternal = `/team-members/${definedInternal}`
  }
  //
  //If theres a color then use the color for
  //
  //if outlined use color for border and text
  //if contained use color for background and contrasttext for text

  const variantColour = {
    outlined: "transparent",
    contained: node?.color?.value,
  }

  const variantTextColour = {
    outlined: node?.color?.value ? node?.color?.value : "var(--primary-main)",
    contained: node?.color?.value
      ? contrastColour(node?.color).contrastText.hex
      : "#ffffff",
  }

  return (
    <Box sx={sx}>
      {node?.link?.internal ? (
        <GatsbyButton
          size="small"
          //color={!node?.color && color}
          variant={variant}
          to={`/${definedInternal}`}
          endIcon={endIcon}
          sx={{
            border: variant !== "text" && `1px solid ${node?.color?.value}`,
            backgroundColor: `${variantColour[variant]} !important`,
            color: `${variantTextColour[variant]} !important`,
          }}
        >
          {node?.text}
        </GatsbyButton>
      ) : definedExternal ? (
        <Button
          size="small"
          //color={!node?.color && color}
          variant={variant}
          href={definedExternal}
          rel="noopener"
          target="_blank"
          endIcon={endIcon}
          sx={{
            border: variant !== "text" && `1px solid ${node?.color?.value}`,
            backgroundColor: variantColour[variant],
            color: `${variantTextColour[variant]} !important`,
          }}
        >
          {node.text}
        </Button>
      ) : null}
    </Box>
  )
}
